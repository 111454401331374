<template>
  <div class="new-sds-container" v-loading="loading">
    <el-tabs type="border-card" v-model="tabModel" @tab-click="handleTabClick">
      <el-tab-pane
        v-loading="loading"
        element-loading-text="data loading"
        name="tab1"
        class="tab"
      >
        <span slot="label"
          ><i class="el-icon-date"></i> {{ tabTitle("Detail Manager") }}</span
        >
        <SdsContentComponentsVue
          :detailData="detailData"
          :language="language"
          :pageStatus="pageStatus"
          @save-sds="saveHandle"
        ></SdsContentComponentsVue>
      </el-tab-pane>
      <el-tab-pane
        v-for="(item, index) in auditedList"
        :key="index"
        :name="'tab' + (index + 2)"
      >
        <span slot="label"
          ><i class="el-icon-date"></i> {{ tabTitle("Version") }}
          {{ item.revNumber }}
        </span>
        <SdsContentComponentsVue
          :detailData="getTabAuditDetailData(item)"
          :specData="specData"
          :language="language"
          :pageStatus="pageStatus"
        ></SdsContentComponentsVue>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SdsContentComponentsVue from "./components/SdsContentComponents.vue";
import { apiServer } from "@/network/modules/detail-api";
export default {
  components: {
    SdsContentComponentsVue,
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      form: {},
      tabModel: "tab1",
      pageStatus: "Edit",
      language: "EN",
      revisionData: [],
      auditedList: [],
      detailData: {},
      rowData: [
        {
          substanceName: "",
          casNumber: "",
          einecsNumber: "",
          rPhrase: "",
          sPhrase: "",
        },
      ],
      specData: {},
    };
  },
  computed: {
    tabTitle() {
      return function (title) {
        if (this.language == "EN") {
          return title;
        }
        if (title == "Detail Manager") {
          return "详情管理";
        } else if (title == "Version") {
          return "版本";
        }
      };
    },
    sdsTypeList() {
      if (this.$route.query.language === "EN") {
        return [
          { name: "Liquid", value: "Liquid" },
          { name: "Oil", value: "Oil" },
          { name: "Powder", value: "Powder" },
        ];
      }
      return [
        { name: "TY-L", value: "TY-L" },
        { name: "TY-Oil", value: "TY-Oil" },
        { name: "TY-P", value: "TY-P" },
      ];
    },
    selectPlaceholderText() {
      if (this.$route.query.language === "EN") {
        return "Please Select";
      } else {
        return "请选择";
      }
    },
    placeholderText() {
      if (this.$route.query.language === "EN") {
        return "Please Input";
      } else {
        return "请输入";
      }
    },
  },
  methods: {
    handleTabClick(tab) {
      if (tab.name === "tab1") {
        let params = { ...this.$route.query };
        if (params.id) {
          this.pageStatus = "Edit";
        } else {
          this.pageStatus = "Add";
        }
      } else {
        this.pageStatus = "Detail";
      }
    },
    // 加载详情数据
    loadDetailData() {
      console.info("loadData==");
      
      let type = this.$route.query.type;
      let id = this.$route.query.id;
      if (id){
        this.loading = true;
        apiServer.getDataByType(type, "getDetail", { id }, (data) => {
          this.handleCopyData(data);
          this.loading = false;
        });
      }
      
    },
    loadAuditListData() {
      
      let type = this.$route.query.type;
      let id = this.$route.query.id;
      if(id){
        this.loading = true;
        apiServer.getDataByType(type, "auditedList", { id }, (data) => {
          this.auditedList = data;
          this.loading = false;
        });
      }
      
    },
    getTabAuditDetailData(item) {
      return JSON.parse(item.jsonValue);
    },
    // 添加行数据
    addRowDataHandle() {
      this.rowData.push({
        substanceName: "",
        casNumber: "",
        einecsNumber: "",
        rPhrase: "",
        sPhrase: "",
      });
    },
    // 删除行数据
    removeRow(idx) {
      this.rowData.splice(idx, 1);
    },
    addVerifyListHandle() {},
    saveHandle(params, callback) {
      let type = this.$route.query.type;
      
      apiServer.getDataByType(type, "saveOrUpdate", params, (data) => {
        this.loading = false;
        callback(data);
        this.$message({
          type: "success",
          message: "Update Success",
        });
      });
    },
    vertifyHandle() {},
    back() {
      this.$router.go(-1);
    },
    handleCopyData(data) {
      if (typeof data == "undefined") {
        return;
      }
      let jsonValue = JSON.parse(data.jsonValue);
      const convertKeys = (obj) => {
        Object.keys(obj).forEach((key) => {
          const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
          if (newKey !== key) {
            obj[newKey] = obj[key];
            delete obj[key];
          }
          if (typeof obj[newKey] === 'object' && obj[newKey] !== null) {
            convertKeys(obj[newKey]);
          }
        });
        return obj;
      };
      jsonValue = convertKeys(jsonValue);
      console.info('jsonValue>>>>>>>>:',jsonValue)
      
      if (this.$route.query.isCopy === "true") {
        jsonValue.itemCode = "";
        jsonValue.revision = [];

        this.detailData = jsonValue;
        this.detailData.id = "";
      } else {
        
        this.detailData.id = data.id;
        this.detailData = jsonValue;
      }

      console.info('detailData>>:',this.detailData)
    },
  },
  created() {
    this.language = this.$route.query.language;
    this.loadDetailData();
    if (this.$route.query.isCopy != "true") {
      this.loadAuditListData();
    }
  },
};
</script>

<style scoped>
@import "../style/new-spec.css";
</style>
